var jquery = require("jquery");
window.$ = window.jQuery = jquery;

import 'bootstrap'
import navigation from './scripts/navigation.js';
import carousel from './scripts/carousel.js';
import jobsList from './scripts/jobsList.js';
import RevealOnScroll from './scripts/revealOnScroll.js';

import  "./index.scss";

// Let the document know when the mouse is being used
document.body.addEventListener("mousedown", function() {
  document.body.classList.add("using-mouse");
});

// Re-enable focus styling when Tab is pressed
document.body.addEventListener("keydown", function(event) {
  if (event.keyCode === 9) {
    document.body.classList.remove("using-mouse");
  }
})


function loadIn() {
  var hero = document.querySelector(".cb-hero");
  var nav = document.querySelector("nav");
  var logo = document.querySelector(".logo");
  var squareBg = document.querySelector(".square-bg");
  var columnsHeading = document.querySelector(".cb-columns__heading-container");
  hero.classList.add("animate");
  nav.classList.add("animate");
  logo.classList.add("animate");
  squareBg.classList.add("animate");
  columnsHeading.classList.add("animate");
}

/* INIT */
window.addEventListener('load', () => {
  loadIn();
	navigation();
  jobsList();
  carousel();
});
