import Swiper from "swiper";
export default function carousel() {
  function initSwiper() {
    $('.cb-carousel').each(function(index, element) {
      var $this = $(this);
      $this.addClass('instance-' + index);

      if ($this.find('.swiper-slide').length > 1) {
        var swiper = new Swiper('.cb-carousel.instance-' + index + ' .swiper-container', {
          init: false,
          pagination: {
            el: '.swiper-pagination',
            dynamicBullets: true,
          },
          speed: 700,
          mousewheel: {
            sensitivity: 1,
            forceToAxis: true,
            invert: true
          },
          preventInteractionOnTransition: true,
          keyboard: {
            enabled: true,
            onlyInViewport: true
          },
          slidesPerView: 1.1,
          // spaceBetween: 16,
          breakpoints: {
            768: {
              slidesPerView: 1,
              spaceBetween: 24,
            },
          },
          autoplay: {
            delay: 5000,
            disableOnInteraction: true,
            waitForTransition: true,
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          }
        });

        swiper.init();

        // fix for showing 1.1 slides on mobile
        if ($(window).width() < 768) {
          swiper.snapGrid[swiper.snapGrid.length - 1] = swiper.slidesGrid[swiper.slidesGrid.length - 1];
        }

        adjustHeight();

        function adjustHeight() { 
          var slideRowHeight = document.querySelector('.slide-row').clientHeight;
          var subHeadingTwo = document.querySelector('.cb-carousel__sub-heading--two');
          $('.swiper-button').each(function() {
            $(this).height(slideRowHeight);
          });
          subHeadingTwo.style.top = (slideRowHeight + 20) + "px";
        }
      }
    });
  }

  var timer;

  $(window).on("resize", function (e) {
    adjustHeight();

    if (timer) {
      clearTimeout(timer);
    }
  
    if (window.innerWidth < 768) {
      timer = setTimeout(initSwiper, 200);
    }
  });

  initSwiper();

  function adjustHeight() { 
    var slideRowHeight = document.querySelector('.slide-row').clientHeight;
    var subHeadingTwo = document.querySelector('.cb-carousel__sub-heading--two');
    $('.swiper-button').each(function() {
      $(this).height(slideRowHeight);
    });
    subHeadingTwo.style.top = (slideRowHeight + 20) + "px";
  }
}
