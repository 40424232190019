export default function navigation() {
  /* elements */
  var icon = document.querySelector(".hamburger");
  var h1Tag = document.querySelector("h1");
  var nav = document.querySelector("nav");
  var logo = document.querySelector(".logo");
  var pathTag = document.getElementsByTagName("path");
  var circle = document.querySelector(".circle");
  var startHiringCta = document.querySelector("section.cb-hero > div > div.cta__container > a.cta.dark");
  var headline = document.querySelector(".cb-hero__headline");
  var footerLink = document.querySelector("footer > div > a");
  var footer = document.querySelector("footer");
  var sections = document.querySelectorAll('section');

  function navToggle(toggle) {
    if (toggle === "toggle") {
      nav.classList.toggle("navShow");
      h1Tag.classList.toggle("logo-forward");
      logo.classList.toggle("logo-forward");
      icon.classList.toggle("clicked");
      circle.classList.toggle("animate");
      icon.setAttribute("aria-expanded", "true");
      footer.setAttribute("aria-hidden", "true");

      if ($('.hamburger').attr('aria-hidden') == 'true') {
        $('.hamburger').attr('aria-hidden', 'false'); 
        $('footer').attr('aria-hidden', 'false');
      } else {
        $('.hamburger').attr('aria-hidden', 'true');
        $('footer').attr('aria-hidden', 'true');
      }

      sections.forEach((element, index) => {
        element.setAttribute("aria-hidden", "true");
      });

      /* invert logo */
      for (var i = 0; i < pathTag.length; i++) {
        pathTag[i].classList.toggle("invert");
      }

      /* if circle element only has class name "circle", set icon element with aria-expanded false */
      if (circle.className === "circle") {
        icon.setAttribute("aria-expanded", "false");
        sections.forEach((element, index) => {
          element.setAttribute("aria-hidden", "false");
        });
      }
    } else if (toggle === "remove") {
      nav.classList.remove("navShow");
      h1Tag.classList.remove("logo-forward");
      logo.classList.remove("logo-forward");
      circle.classList.remove("animate");
      icon.classList.remove("clicked");
      footer.setAttribute("aria-hidden", "false");

      for (var i = 0; i < pathTag.length; i++) {
        pathTag[i].classList.remove("invert");
      }

      /* if circle element only has class name "circle", set icon element with aria-expanded false */
      if (circle.className === "circle") {
        icon.setAttribute("aria-expanded", "false");
      }

      sections.forEach((element, index) => {
        element.setAttribute("aria-hidden", "false");
      });
    }
  }
  /* circle icon event click */
  document.body.addEventListener('click', function (event) {
    if (icon.contains(event.target)) {
      navToggle("toggle");
    } else if (icon.contains(event.target)) {
    } else {
      if (icon.classList.contains('clicked')) {
        navToggle("toggle");
      }
    }
  });

  /* window event scroll - remove classes when scrolling down + close the navmenu + invert logo back */
  window.addEventListener("scroll", function () {
    navToggle("remove");
  });

  /* focus/active element - if SubmitCvCta is active/on focus, remove following classes + close the navmenu + invert logo back */
  function isActiveElement(element) {
    if (element === document.activeElement) {
      return true;
    }
  }

  setInterval(() => {
    if (isActiveElement(headline) || isActiveElement(startHiringCta) || isActiveElement(footerLink)) {
      navToggle("remove");
    }
  }, 500);

  // if (circle.className === "circle animate") {
  //   if (isActiveElement) {
  //     nav.classList.remove("navShow");
  //     h1Tag.classList.remove("logo-forward");
  //     logo.classList.remove("logo-forward");
  //     circle.classList.remove("circle-animate");
  //     icon.classList.remove("hamburger-forward");

  //     for (var i = 0; i < pathTag.length; i++) {
  //       pathTag[i].classList.remove("invert");
  //     }
  //   }
  // }
}
