export default function jobsList() {
  const scrollBox = document.querySelector(".cb-standard__scrollbox");

	fetch("https://06yzeb0z81.execute-api.eu-west-1.amazonaws.com/default/bullhorn-proxy")
	.then((res)=>res.json())
	.then((jobs) => {
		scrollBox.innerHTML = "";
		const listEl = document.createElement("div");
		for(var i=0; i<jobs.length; i++) {
			const job = jobs[i];
			let city = job.address.city
			if (city !== null) {
        city = `<span class="cb-standard__scrollbox--job-title">${job.address.city} |</span> `
			} else {
				city = ""
			}
			if (job.employmentType === undefined) {
				job.employmentType = ""
			}
			const jobEl = document.createElement("a");
			jobEl.setAttribute('href', `/career-portal/index.html#/jobs/${job.id}`)
			jobEl.setAttribute('class', 'cb-standard__scrollbox--job')
			jobEl.setAttribute('target', '_blank')
			jobEl.setAttribute('rel', 'noopener noreferrer')

			listEl.setAttribute('class', 'cb-standard__scrollbox-inner')

			jobEl.innerHTML = `<span class="cb-standard__scrollbox--job-pos">${job.title} <span class="arrow" aria-hidden="true">-&gt;</span></span>` + `${city}` + `<span class="cb-standard__scrollbox--job-title">${job.employmentType}</span>`;
			
			listEl.appendChild(jobEl);
		}
		scrollBox.appendChild(listEl);
	})
}