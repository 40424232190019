import waypoints from "../../node_modules/waypoints/lib/noframework.waypoints";
var jquery = require("jquery");
window.$ = window.jQuery = jquery;

class RevealOnScroll {
  constructor(els, offset) {
    this.itemsToReveal = els;
    this.offsetPercentage = offset;
    this.hideInitially();
    this.createWaypoints();
  }

  hideInitially() {
    this.itemsToReveal.addClass("reveal-item");
  }

  createWaypoints() {
    var that = this;
    this.itemsToReveal.each(function() {
      var currentItem = this;
      new Waypoint({
        element: currentItem,
        handler: function() {
          $(currentItem).addClass("reveal-item--revealed");
        },
        offset: that.offsetPercentage
      });
    });
  }
}

// new RevealOnScroll($(".cb-columns h2"), "95%");
new RevealOnScroll($(".cb-columns__col--image"), "95%");
new RevealOnScroll($(".cb-columns h3, .cb-columns p"), "95%");
new RevealOnScroll($(".cb-standard h2, .cb-standard h3, .cb-standard p, .cb-standard a, .cb-standard img, .cb-standard .cb-standard__scrollbox"), "95%");
new RevealOnScroll($(".cb-carousel h2, .cb-carousel h3, .cb-carousel .cb-carousel__sub-heading, .cb-carousel .cb-carousel__heading, .cb-carousel .swiper-button, .cb-carousel a, .swiper-pagination, .swiper-container"), "95%");
new RevealOnScroll($(".cb-carousel a"), "95%");